* {
  --primary-color: #43beac;
  --primary-color--dark: #30a39d;
  --primary-color--light: #e0f4f1;

  --dark-color--blue-grey: #37474f;

  --secondary-color--blue: #3b9dc5;
  --secondary-color--blue-dark: #216682;
  --secondary-color--blue-light: #e0f4f1;

  --secondary-color--orange: #eda822;
  --secondary-color--orange-dark: #ba851a;
  --secondary-color--orange-light: #f0c36a;

  --white: #fff;
  --grey-10: #f8f8f8;
  --grey-15: #f4f4f4;
  --grey-20: #e6e6e8;
  --grey-25: #d3d4d5;
  --grey-30: #bebfc0;
  --grey-40: #a8aaab;
  --grey-50: #929597;
  --grey-60: #7c7f82;
  --grey-80: #515558;
  --grey-90: #3b3f43;
  --grey-transparent: rgba(81, 85, 88, 0.25);
  --text-color: #252a2e;

  --error-color: #d32f2f;
}
