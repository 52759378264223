@import url('./fonts/Inter/inter.css?family=Inter:wght@300;400;700;900&display=swap');
@import url('./fonts/Roboto/roboto.css?family=Roboto:wght@300;400;700;900&display=swap');
@import './variable';

html {
  scroll-behavior: smooth;
  overscroll-behavior: none;
}

body {
  max-height: 100vh;
  /* Mobile safari fix */
  min-height: -webkit-fill-available;
  text-rendering: optimizeSpeed;
  line-height: 1.5;
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: 'Inter', 'Roboto', 'sans-serif';
  font-size: 16px;
  color: var(--text-color);
}
